import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  InputHTMLAttributes,
} from 'react';

import { useField } from '@unform/core';
import { Link } from 'react-router-dom';
import { FiAlertCircle, FiEye, FiEyeOff } from 'react-icons/fi';

import { colorPrimary } from '../../styles/global';

import {
  Eye,
  Label,
  Error,
  Container,
  StyledInput,
  BottomError,
} from './styles';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  title: string;
  hasForgotLink?: boolean;
  hasPasswordEye?: boolean;
  errorLocation?: 'BOTTOM' | 'RIGHT';
  handleTogglePassword?: () => void;
}

const Input: React.FC<IInputProps> = ({
  name,
  type,
  title,
  hasForgotLink,
  hasPasswordEye,
  errorLocation = 'RIGHT',
  handleTogglePassword,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [hasFocus, setHasFocus] = useState(false);
  const [hasContent, setHasContent] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleOnFocus = useCallback(() => {
    setHasFocus(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setHasFocus(false);
    setHasContent(!!inputRef.current?.value);
  }, []);

  return (
    <Container>
      <Label htmlFor={name}>
        {title}
        {hasForgotLink && <Link to="/forgot-password">Esqueceu a senha?</Link>}
      </Label>
      <StyledInput
        type={type}
        ref={inputRef}
        hasError={!!error}
        hasFocus={hasFocus}
        hasContent={hasContent}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        defaultValue={defaultValue}
        {...rest}
      />
      {hasPasswordEye && (
        <Eye onClick={handleTogglePassword}>
          {type === 'password' ? <FiEyeOff size={20} /> : <FiEye size={20} />}
        </Eye>
      )}
      {error && errorLocation === 'RIGHT' && (
        <Error title={error}>
          <FiAlertCircle color={colorPrimary} size={20} />
        </Error>
      )}
      {error && errorLocation === 'BOTTOM' && (
        <BottomError>{error}</BottomError>
      )}
    </Container>
  );
};

export default Input;
