import React from 'react';

import { Container } from './styles';

interface IRememberMeProps {
  checked: boolean;
  onChange: () => void;
}

const RememberMe: React.FC<IRememberMeProps> = ({ checked, onChange }) => {
  return (
    <Container>
      <input
        type="checkbox"
        id="checkbox"
        defaultChecked={checked}
        onChange={onChange}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="checkbox">Lembrar de mim</label>
    </Container>
  );
};

export default RememberMe;
