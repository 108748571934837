import { AxiosError } from 'axios';
import { ValidationError } from 'yup';

interface Errors {
  [key: string]: string;
}

export const getValidationErrors = (err: ValidationError): Errors => {
  const validationErrors: Errors = {};

  err.inner.forEach(error => {
    validationErrors[error.path || ''] = error.message;
  });

  return validationErrors;
};

export const getSignUpErrors = (err: AxiosError): boolean => {
  return err.response?.data.errors?.messages[0]?.includes(
    'já está sendo utilizado',
  );
};

export const getSignInErrors = (err: AxiosError): string => {
  if (err.isAxiosError) {
    if (Array.isArray(err.response?.data?.errors?.messages)) {
      return err.response?.data.errors.messages[0];
    }
    if (err.response?.data.errors?.message) {
      return err.response?.data.errors?.message;
    }
    if (err.response?.data?.message) {
      return err.response?.data?.message;
    }
  }

  return 'Verifique sua conexão e tente novamente.';
};
