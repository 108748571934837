import React, { useCallback, useEffect, useState } from 'react';

import { useSpring } from 'react-spring';
import { FiAlertCircle, FiX } from 'react-icons/fi';

import { colorPrimary } from '../../styles/global';

import { Container, Content, Message, DismissButton, Wrapper } from './styles';

interface ISnackProps {
  message: string;
  onDismiss: () => void;
}

const Snack: React.FC<ISnackProps> = ({ message, onDismiss }) => {
  const [open, setOpen] = useState(true);

  const props = useSpring(
    open
      ? {
          to: { opacity: 1, bottom: 16 },
          from: { opacity: 0, bottom: 0 },
        }
      : {
          to: { opacity: 0, bottom: 0 },
          from: { opacity: 1, bottom: 16 },
        },
  );

  const handleOnDismiss = useCallback(() => {
    setOpen(false);

    setTimeout(() => {
      onDismiss();
    }, 300);
  }, [onDismiss]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleOnDismiss();
    }, 3000);

    return () => clearTimeout(timeout);
  });

  return (
    <Container style={props}>
      <Content>
        <Wrapper>
          <div>
            <FiAlertCircle size={24} />
          </div>
          <Message>{message}</Message>
        </Wrapper>
        <DismissButton onClick={handleOnDismiss}>
          <FiX size={26} color={colorPrimary} />
        </DismissButton>
      </Content>
    </Container>
  );
};

export default Snack;
