import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  font-size: 14px;
  margin-top: 16px;

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label {
    display: block;
    position: relative;

    padding-left: 35px;
    margin-bottom: 20px;

    cursor: pointer;

    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  input[type='checkbox'] + label:last-child {
    margin-bottom: 0;
  }

  input[type='checkbox'] + label:before {
    content: '';
    width: 20px;
    height: 20px;

    display: block;

    border: 2px solid var(--color-primary);

    top: 0;
    left: 0;
    position: absolute;

    opacity: 0.6;

    transition: all 0.12s, border-color 0.08s;
  }

  input[type='checkbox']:checked + label:before {
    width: 10px;

    top: -5px;
    left: 5px;

    opacity: 1;

    border-top-color: transparent;
    border-left-color: transparent;

    transform: rotate(45deg);
  }
`;
