import React, { useState, useCallback, useRef, useEffect } from 'react';

import * as Yup from 'yup';
import qs from 'query-string';
import Lottie from 'lottie-react-web';
import { FormHandles } from '@unform/core';
import { FiArrowLeft } from 'react-icons/fi';
import { AiFillHeart } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from '../../store';
import { IAuthState } from '../../store/modules/auth/types';

import {
  resetError,
  resetSuccess,
  signUpRequest,
  storeRedirect,
  storeSubdomain,
} from '../../store/modules/auth/actions';

import { getValidationErrors } from '../../utils/errors';
import animation from '../../assets/animations/loading-signin.json';

import Input from '../../components/Input';
import Snack from '../../components/Snack';

import { colorPrimary, colorWhiteTransparency } from '../../styles/global';

import {
  Header,
  Button,
  Footer,
  Content,
  Container,
  BackButton,
  Background,
  SignUpForm,
  ButtonRipple,
  InputContainer,
} from './styles';

interface ISignUpFormDataProps {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const SignInPage: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const dispatch = useDispatch();

  const history = useHistory();

  const { error, token, loading, redirect, subdomain } = useSelector<
    IState,
    IAuthState
  >(state => state.auth);

  const [localError, setLocalError] = useState('');
  const [captchaValidated, setCaptchaValidated] = useState(false);
  const [inputType, setInputType] = useState<'password' | 'text'>('password');

  useEffect(() => {
    if (history.location.search) {
      const { redirect: searchRedirect, subdomain: searchSubdomain } = qs.parse(
        history.location.search,
      );

      if (
        searchRedirect &&
        searchSubdomain &&
        typeof searchRedirect === 'string' &&
        typeof searchSubdomain === 'string'
      ) {
        dispatch(storeRedirect(searchRedirect));
        dispatch(storeSubdomain(searchSubdomain));
      } else {
        history.push('/error');
      }
    } else if (!subdomain || !redirect) {
      history.push('/error');
    }
  }, [history, dispatch, redirect, subdomain]);

  useEffect(() => {
    if (!subdomain || !redirect) {
      history.push('error');
    }
  }, [history, subdomain, redirect]);

  const handleNavigateToSignIn = useCallback(() => {
    history.push('/signin');
  }, [history]);

  useEffect(() => {
    if (redirect && subdomain && token) {
      switch (process.env.REACT_APP_ENV) {
        case 'production': {
          window.location.replace(
            `https://${subdomain}.bsfood.com.br/loading/?token=${token}&redirect=${redirect}`,
          );
          break;
        }

        case 'staging': {
          window.location.replace(
            `https://menustaging.bsfood.com.br/loading/?token=${token}&redirect=${redirect}`,
          );
          break;
        }

        default: {
          window.location.replace(
            `http://localhost:3000/loading/?token=${token}&redirect=${redirect}`,
          );
        }
      }
    }
  }, [redirect, subdomain, token]);

  useEffect(() => {
    if (error) {
      if (error === 'O email já está em uso.') {
        formRef.current?.setFieldError('email', error);
      } else {
        setLocalError(error);
      }

      dispatch(resetError());
    }
  }, [error, dispatch]);

  useEffect(() => {
    dispatch(resetSuccess());
  }, [dispatch]);

  const handleOnBackPressed = useCallback(() => {
    handleNavigateToSignIn();
  }, [handleNavigateToSignIn]);

  const handleSignUp = useCallback(
    async (data: ISignUpFormDataProps) => {
      if (loading) {
        return;
      }

      let captcha: string | null | undefined;

      if (!captchaValidated) {
        captcha = await recaptchaRef.current?.executeAsync();
        setCaptchaValidated(true);
      } else {
        captcha = recaptchaRef.current?.getValue();
      }

      if (!captcha) {
        setLocalError('CAPTCHA inválido!');
        return;
      }

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          name: Yup.string()
            .min(3, 'Mínimo de 3 caracteres.')
            .required('O nome é obrigatório.'),
          email: Yup.string()
            .email('Digite um email válido.')
            .required('O email é obrigatório.'),
          password: Yup.string().min(
            6,
            'A senha deve conter, no mínimo, 6 caracteres.',
          ),
          confirmPassword: Yup.string()
            .when('password', {
              is: (val: string) => !!val.length,
              then: Yup.string().required('A senha é obrigatória.'),
              otherwise: Yup.string(),
            })
            .oneOf([Yup.ref('password'), undefined], 'As senhas não conferem.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        dispatch(signUpRequest(data));
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        }
      }
    },
    [loading, captchaValidated, dispatch],
  );

  const handleOnTogglePassword = useCallback(() => {
    setInputType(prevState => {
      if (prevState === 'password') {
        return 'text';
      }

      return 'password';
    });
  }, []);

  const handleOnErrorDismissed = useCallback(() => {
    setLocalError('');
  }, []);

  const handleOnTokenExpired = useCallback(() => {
    setCaptchaValidated(false);
  }, []);

  return (
    <Container>
      <Content>
        <Header>
          <BackButton onClick={handleOnBackPressed}>
            <FiArrowLeft size={24} />
            <span>Voltar ao login</span>
          </BackButton>
        </Header>
        <SignUpForm ref={formRef} onSubmit={handleSignUp}>
          <h2>Registre-se.</h2>
          <InputContainer>
            <Input
              type="text"
              name="name"
              title="Nome"
              placeholder="Seu nome completo"
            />
            <Input
              name="email"
              type="email"
              title="Email"
              errorLocation="BOTTOM"
              placeholder="você@exemplo.com.br"
            />
            <Input
              title="Senha"
              name="password"
              hasPasswordEye
              type={inputType}
              placeholder="Sua senha"
              handleTogglePassword={handleOnTogglePassword}
            />
            <Input
              hasPasswordEye
              type={inputType}
              name="confirmPassword"
              title="Confirme a senha"
              placeholder="Confirme a senha"
              handleTogglePassword={handleOnTogglePassword}
            />
          </InputContainer>
          <Button type="submit">
            <ButtonRipple color={colorWhiteTransparency}>
              {loading ? (
                <Lottie
                  options={{
                    animationData: animation,
                  }}
                  height={60}
                  width={60}
                />
              ) : (
                <span>Registrar</span>
              )}
            </ButtonRipple>
          </Button>
          <button type="button" onClick={handleNavigateToSignIn}>
            Já tem uma conta?
            <strong>Entre agora!</strong>
          </button>
          <Footer>
            <span>
              Feito com
              <AiFillHeart color={colorPrimary} size={20} />
              por
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://bsfood.com.br/"
              >
                Build Solutions
              </a>
              <span>.</span>
            </span>
          </Footer>
        </SignUpForm>
      </Content>
      <Background />
      <ReCAPTCHA
        size="invisible"
        ref={recaptchaRef}
        onExpired={handleOnTokenExpired}
        sitekey={`${process.env.REACT_APP_CAPTCHA_KEY}`}
      />
      {localError && (
        <Snack message={localError} onDismiss={handleOnErrorDismissed} />
      )}
    </Container>
  );
};

export default SignInPage;
