import React, { useCallback, useEffect, useState } from 'react';

import * as Yup from 'yup';
import qs from 'query-string';
import Lottie from 'lottie-react-web';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from '../../store';
import { IAuthState } from '../../store/modules/auth/types';

import {
  resetError,
  storeRedirect,
  storeSubdomain,
  resetPasswordRequest,
  forgotPasswordRequest,
} from '../../store/modules/auth/actions';

import illustration from '../../assets/forgot-password.svg';
import animation from '../../assets/animations/loading-signin.json';
import acquisition_completed from '../../assets/animations/acquisition-completed.json';

import Snack from '../../components/Snack';
import FormlessInput from '../../components/FormlessInput';

import {
  Form,
  Title,
  Border,
  Header,
  Content,
  Message,
  Wrapper,
  TopRight,
  Container,
  BottomLeft,
  ButtonRipple,
  InputContainer,
  SuccessMessage,
  BorderContainer,
} from './styles';

const ForgotPasswordPage: React.FC = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { error, loading, success, redirect, subdomain } = useSelector<
    IState,
    IAuthState
  >(state => state.auth);

  const [email, setEmail] = useState(() => {
    if (history.location.search) {
      const parsed = qs.parse(history.location.search);

      if (parsed.token) {
        return parsed.email as string;
      }
    }

    return '';
  });

  const [localError, setLocalError] = useState('');

  const [emailError, setEmailError] = useState('');

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const [resetToken] = useState(() => {
    if (history.location.search) {
      const parsed = qs.parse(history.location.search);

      if (parsed.token) {
        return parsed.token as string;
      }
    }

    return '';
  });

  useEffect(() => {
    if (history.location.search) {
      const { redirect: searchRedirect, subdomain: searchSubdomain } = qs.parse(
        history.location.search,
      );

      if (
        searchRedirect &&
        searchSubdomain &&
        typeof searchRedirect === 'string' &&
        typeof searchSubdomain === 'string' &&
        !redirect &&
        !subdomain
      ) {
        dispatch(storeRedirect(searchRedirect));
        dispatch(storeSubdomain(searchSubdomain));
      }
    }
  }, [history, redirect, subdomain, dispatch]);

  useEffect(() => {
    if (error) {
      if (error.includes('Facebook')) {
        setEmailError(error);
      } else {
        setLocalError(error);
      }

      dispatch(resetError());
    }
  }, [error, dispatch]);

  const handleOnBackPressed = useCallback(() => {
    history.push('/signin');
  }, [history]);

  const handleOnSubmit = useCallback(async () => {
    if (loading) return;

    if (resetToken) {
      try {
        const schema = Yup.object().shape({
          password: Yup.string().min(
            6,
            'A senha deve conter, no mínimo, 6 caracteres.',
          ),
          confirmPassword: Yup.string()
            .when('password', {
              is: (value: string) => value.length >= 6,
              then: Yup.string()
                .min(6, 'A senha deve conter, no mínimo, 6 caracteres.')
                .required('Confirmação de senha é obrigatória.'),
            })
            .oneOf([Yup.ref('password'), undefined], 'As senhas não conferem.'),
        });

        await schema.validate(
          { password, confirmPassword },
          { abortEarly: false },
        );

        setPasswordError('');

        dispatch(
          resetPasswordRequest({
            email,
            password,
            resetToken,
            confirmPassword,
          }),
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          if (!password) {
            setPasswordError('A senha é obrigatória.');
          } else if (password.length < 6) {
            setPasswordError('A senha precisa ter, no mínimo, 6 caracteres.');
          }

          if (!confirmPassword) {
            setConfirmPasswordError('A senha é obrigatória.');
          } else if (confirmPassword.length < 6) {
            setConfirmPasswordError(
              'A senha precisa ter, no mínimo, 6 caracteres.',
            );
          }

          if (confirmPassword !== password) {
            setConfirmPasswordError('As senhas não conferem.');
          }
        }
      }
    } else {
      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um email válido.')
            .required('O email é obrigatório.'),
        });

        await schema.validate({ email }, { abortEarly: false });

        setEmailError('');

        dispatch(
          forgotPasswordRequest({
            email,
            redirect,
            subdomain,
          }),
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          if (!email) {
            setEmailError('O email é obrigatório.');
          } else {
            setEmailError('Email inválido.');
          }
        }
      }
    }
  }, [
    email,
    loading,
    password,
    redirect,
    subdomain,
    resetToken,
    confirmPassword,
    dispatch,
  ]);

  const handleOnPasswordChanged = useCallback((value: string) => {
    setPassword(value);
    setPasswordError('');
  }, []);

  const handleOnConfirmPasswordChanged = useCallback((value: string) => {
    setConfirmPassword(value);
    setConfirmPasswordError('');
  }, []);

  const handleOnEmailChanged = useCallback((value: string) => {
    setEmail(value);
    setEmailError('');
  }, []);

  const handleOnErrorDismissed = useCallback(() => {
    setLocalError('');
  }, []);

  return (
    <Container>
      <Header onClick={handleOnBackPressed}>
        <FiArrowLeft size={24} />
        <span>voltar</span>
      </Header>
      {success === 'RESET' || success === 'FORGOT' ? (
        <Content>
          <SuccessMessage>
            <Lottie
              options={{
                animationData: acquisition_completed,
                loop: false,
              }}
              height={window.innerWidth < 600 ? 150 : 250}
              width={window.innerWidth < 600 ? 150 : 250}
            />
            <Wrapper>
              {resetToken ? (
                <>
                  <strong>Prontinho!</strong>
                  <span>Sua senha foi alterada com sucesso.</span>
                </>
              ) : (
                <>
                  <strong>Email enviado!</strong>
                  <span>
                    Verifique sua caixa de entrada para concluir o processo de
                    redefinição da senha.
                  </span>
                </>
              )}
            </Wrapper>
          </SuccessMessage>
        </Content>
      ) : (
        <Content>
          <img src={illustration} alt="" />
          <Form>
            <Border />
            {resetToken && email ? (
              <>
                <Title>Alterar senha.</Title>
                <Message>Insira a nova senha abaixo.</Message>
                <InputContainer>
                  <FormlessInput
                    title="Senha"
                    name="oi6auv7q"
                    type="password"
                    value={password}
                    placeholder="Sua nova senha"
                    error={passwordError}
                    onChange={e => handleOnPasswordChanged(e.target.value)}
                  />
                  <FormlessInput
                    title="Confirmar senha"
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    placeholder="Confirme a senha"
                    error={confirmPasswordError}
                    onChange={e =>
                      handleOnConfirmPasswordChanged(e.target.value)
                    }
                  />
                </InputContainer>
              </>
            ) : (
              <>
                <Title>Esqueceu a senha?</Title>
                <Message>
                  Inisira seu email cadastrado para receber as instruções de
                  recuperação de senha.
                </Message>
                <InputContainer>
                  <FormlessInput
                    type="email"
                    title="Email"
                    name="e7w2ycmv"
                    error={emailError}
                    errorLocation="BOTTOM"
                    placeholder="você@exemplo.com.br"
                    onChange={e => handleOnEmailChanged(e.target.value)}
                  />
                </InputContainer>
              </>
            )}
            <ButtonRipple color="#fff8" onClick={handleOnSubmit}>
              {loading ? (
                <Lottie
                  width={60}
                  height={60}
                  options={{
                    animationData: animation,
                  }}
                />
              ) : (
                <span>Enviar</span>
              )}
            </ButtonRipple>
            <BorderContainer>
              <Border />
            </BorderContainer>
          </Form>
        </Content>
      )}
      <TopRight />
      <BottomLeft />
      {localError && (
        <Snack message={localError} onDismiss={handleOnErrorDismissed} />
      )}
    </Container>
  );
};

export default ForgotPasswordPage;
