import ISignInRequest from '../../../dtos/ISignInRequest';
import ISignUpRequest from '../../../dtos/ISignUpRequest';
import IResetPasswordRequest from '../../../dtos/IResetPasswordRequest';
import IForgotPasswordRequest from '../../../dtos/IForgotPasswordRequest';

import { PayloadAction, ICommonState, Action } from '../types';
import ISignInFacebookRequest from '../../../dtos/ISignInFacebookRequest';

export enum ActionTypes {
  RESET_ERROR = 'RESET_ERROR',
  RESET_SUCCESS = 'RESET_SUCCESS',
  STORE_REDIRECT = 'STORE_REDIRECT',
  STORE_SUBDOMAIN = 'STORE_SUBDOMAIN',
  SIGN_IN_REQUEST = 'SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE = 'SIGN_IN_FAILURE',
  SIGN_UP_REQUEST = 'SIGN_UP_REQUEST',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE',
  FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE',
  FACEBOOK_SIGN_IN_REQUEST = 'FACEBOOK_SIGN_IN_REQUEST',
}

type ISuccess = 'RESET' | 'FORGOT' | 'NONE';

export interface IAuthState extends ICommonState {
  token: string;
  redirect: string;
  subdomain: string;
  success: ISuccess;
  facebookLoading: boolean;
}

export type ResetErrorAction = Action<ActionTypes.RESET_ERROR>;

export type ResetSuccessAction = Action<ActionTypes.RESET_SUCCESS>;

export type StoreRedirectAction = PayloadAction<
  ActionTypes.STORE_REDIRECT,
  string
>;

export type StoreSubdomainAction = PayloadAction<
  ActionTypes.STORE_SUBDOMAIN,
  string
>;

export type SignInRequestAction = PayloadAction<
  ActionTypes.SIGN_IN_REQUEST,
  ISignInRequest
>;

export type SignInSuccessAction = PayloadAction<
  ActionTypes.SIGN_IN_SUCCESS,
  string
>;
export type SignInFailureAction = PayloadAction<
  ActionTypes.SIGN_IN_FAILURE,
  string
>;

export type SignUpRequestAction = PayloadAction<
  ActionTypes.SIGN_UP_REQUEST,
  ISignUpRequest
>;

export type ResetPasswordRequestAction = PayloadAction<
  ActionTypes.RESET_PASSWORD_REQUEST,
  IResetPasswordRequest
>;

export type ResetPasswordSuccessAction = Action<ActionTypes.RESET_PASSWORD_SUCCESS>;

export type ResetPasswordFailureAction = PayloadAction<
  ActionTypes.RESET_PASSWORD_FAILURE,
  string
>;

export type ForgotPasswordRequestAction = PayloadAction<
  ActionTypes.FORGOT_PASSWORD_REQUEST,
  IForgotPasswordRequest
>;

export type ForgotPasswordSuccessAction = Action<ActionTypes.FORGOT_PASSWORD_SUCCESS>;

export type ForgotPasswordFailureAction = PayloadAction<
  ActionTypes.FORGOT_PASSWORD_FAILURE,
  string
>;

export type FacebookSignInRequestAction = PayloadAction<
  ActionTypes.FACEBOOK_SIGN_IN_REQUEST,
  ISignInFacebookRequest
>;

export type AuthActions =
  | ResetErrorAction
  | ResetSuccessAction
  | StoreRedirectAction
  | SignInRequestAction
  | SignInSuccessAction
  | SignInFailureAction
  | SignUpRequestAction
  | StoreSubdomainAction
  | ResetPasswordRequestAction
  | ResetPasswordSuccessAction
  | ResetPasswordFailureAction
  | ForgotPasswordRequestAction
  | ForgotPasswordSuccessAction
  | ForgotPasswordFailureAction
  | FacebookSignInRequestAction;
