import React from 'react';

import access_denied from '../../assets/access-denied.svg';

import { Image, Content, Message, Container } from './styles';

const ErrorPage: React.FC = () => {
  return (
    <Container>
      <Content>
        <Image src={access_denied} />
      </Content>
      <Message>
        Parece que você não chegou aqui através de um cardápio, acesse o
        cardápio do seu estabelecimento favorito e efetue o login por lá (:
      </Message>
    </Container>
  );
};

export default ErrorPage;
