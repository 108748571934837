import { all, takeLatest, call, put } from 'redux-saga/effects';

import Api from '../../../services/api';

import {
  ActionTypes,
  SignInRequestAction,
  SignUpRequestAction,
  ResetPasswordRequestAction,
  ForgotPasswordRequestAction,
  FacebookSignInRequestAction,
} from './types';

import {
  signInSuccess,
  signInFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
} from './actions';

import { getSignInErrors, getSignUpErrors } from '../../../utils/errors';

function* signInRequest(action: SignInRequestAction) {
  try {
    const response = yield call(Api.auth.signIn, action.payload);
    yield put(signInSuccess(response));
  } catch (err) {
    yield put(signInFailure(getSignInErrors(err)));
  }
}

function* signUpRequest(action: SignUpRequestAction) {
  try {
    const response = yield call(Api.auth.signUp, action.payload);
    yield put(signInSuccess(response));
  } catch (err) {
    const error = getSignUpErrors(err)
      ? 'O email já está em uso.'
      : 'Verifique sua conexão e tente novamente.';
    yield put(signInFailure(error));
  }
}

function* resetPassword(action: ResetPasswordRequestAction) {
  try {
    yield call(Api.auth.resetPassword, action.payload);
    yield put(resetPasswordSuccess());
  } catch (err) {
    yield put(resetPasswordFailure(getSignInErrors(err)));
  }
}

function* forgotPassword(action: ForgotPasswordRequestAction) {
  try {
    yield call(Api.auth.forgotPassword, action.payload);
    yield put(forgotPasswordSuccess());
  } catch (err) {
    yield put(forgotPasswordFailure(getSignInErrors(err)));
  }
}

function* facebookSignInRequest(action: FacebookSignInRequestAction) {
  try {
    const response = yield call(Api.auth.facebookSignIn, action.payload);
    yield put(signInSuccess(response));
  } catch (err) {
    yield put(signInFailure(err.message));
  }
}

export default all([
  takeLatest(ActionTypes.SIGN_IN_REQUEST, signInRequest),
  takeLatest(ActionTypes.SIGN_UP_REQUEST, signUpRequest),
  takeLatest(ActionTypes.RESET_PASSWORD_REQUEST, resetPassword),
  takeLatest(ActionTypes.FORGOT_PASSWORD_REQUEST, forgotPassword),
  takeLatest(ActionTypes.FACEBOOK_SIGN_IN_REQUEST, facebookSignInRequest),
]);
