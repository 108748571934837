import styled, { keyframes } from 'styled-components';

import { Form } from '@unform/web';
import Ripple from 'react-ripples';

import background from '../../assets/login-background.jpg';

interface IProgressNumberProps {
  selected: boolean;
}

interface IHorizontalContainerProps {
  reverse?: boolean;
  symmetric?: boolean;
}

const appearFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to{
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  display: flex;
  transition: flex-direction 0.4s;

  overflow: hidden;
`;

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 650px;
  min-width: 650px;

  display: flex;
  flex-direction: column;

  background: var(--color-white);

  top: 0;
  overflow-y: auto;
  animation: ${appearFromLeft} 0.8s;

  @media (max-width: 650px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100vh;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${background});
`;

export const Header = styled.header`
  display: flex;
  padding: 32px 32px 16px 32px;
  background: var(--color-white);
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  span {
    font-size: 14px;

    @media (min-width: 800px) {
      font-size: 16px;
    }
  }

  border: 0;
  outline: 0;
  background: none;
`;

export const SignUpForm = styled(Form)`
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 0px 86px 0 86px;
  background: var(--color-white);

  > h2 {
    font-size: 40px;
    margin-bottom: 32px;
  }

  h3 {
    margin-bottom: 32px;
  }

  > button + button {
    background: none;
    text-decoration: none;

    border: 0;
    outline: 0;

    color: var(--color-black);

    font-size: 16px;
    text-align: center;

    margin-top: 32px;
    transition: opacity 0.3s;

    strong {
      margin-left: 6px;
    }
  }

  > button + button:hover {
    opacity: 0.6;
  }

  @media (max-width: 650px) {
    padding: 32px;
  }
`;

export const Progress = styled.div`
  display: flex;
  margin-bottom: 64px;
`;

export const ProgressItem = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;

export const ProgressNumber = styled.span<IProgressNumberProps>`
  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 8px;
  background: ${({ selected }) =>
    selected ? 'var(--color-primary)' : 'var(--color-control-light'};
`;

export const ProgressStroke = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Stroke = styled.div`
  width: 100%;
  padding-bottom: 32px;
  border-top: 2px solid var(--color-primary);
`;

export const InputContainer = styled.div`
  > div {
    margin-bottom: 32px;
  }
`;

export const HorizontalContainer = styled.div<IHorizontalContainerProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};

  > div {
    width: ${({ symmetric }) => (symmetric ? '50%' : '100%')};
    margin-left: ${({ reverse }) => (reverse ? '16px' : '0')};
    margin-right: ${({ reverse }) => (reverse ? '0' : '16px')};
  }

  > div + div {
    margin-left: 0;
    margin-right: 0;
    width: ${({ symmetric }) => (symmetric ? '50%' : '30%')};
  }

  @media (max-width: 650px) {
    > div {
      margin-left: ${({ reverse }) => (reverse ? '8px' : '0')};
      margin-right: ${({ reverse }) => (reverse ? '0' : '8px')};
    }
  }
`;

export const ButtonRipple = styled(Ripple)`
  width: 100%;
  height: 60px;
  min-height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  border-radius: 32px 0 32px 32px;
  background: var(--color-primary);
`;

export const Button = styled.button`
  height: 60px;
  min-height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  margin-top: 32px;
  color: var(--color-white);
  border-radius: 32px 0 32px 32px;
  background: var(--color-primary);
  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);

  cursor: pointer;
  transition: box-shadow 0.4s;

  &:hover {
    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: auto;

  background: var(--color-white);

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    text-decoration: none;
    color: var(--color-primary);

    font-size: 16px;
    text-align: center;

    margin-left: 4px;
    transition: opacity 0.3s;
  }

  a:hover {
    opacity: 0.6;
  }

  svg {
    margin: 0 4px;
  }

  margin-bottom: 32px;

  @media (max-height: 900px) {
    margin-top: 32px;
  }
`;
