import Ripple from 'react-ripples';
import styled from 'styled-components';

import topRight from '../../assets/top-right.svg';
import bottomLeft from '../../assets/bottom-left.svg';

export const Container = styled.div`
  height: 100vh;

  position: relative;

  display: flex;
  flex-direction: column;

  min-height: 860px;

  overflow: hidden;

  @media (max-height: 860px) {
    height: 860px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  height: 60px;

  cursor: pointer;

  margin: 32px;

  z-index: 2;

  span {
    margin-left: 16px;
  }

  @media (min-width: 541px) {
    padding-bottom: 8px;
    border-bottom: 2px solid var(--color-primary);
  }
`;

export const Content = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: -132px;
  padding: 0 32px;

  img {
    width: 500px;
    height: 500px;

    @media (max-width: 1200px) {
      width: 400px;
      height: 400px;
    }

    @media (max-width: 1080px) {
      display: none;
    }
  }

  @media (max-width: 470px) {
    align-items: flex-start;
    margin-top: 72px;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 128px;

  @media (max-width: 1080px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const BorderContainer = styled.div`
  width: 100%;
  margin-top: 32px;

  display: flex;
  justify-content: flex-end;
`;

export const Border = styled.div`
  width: 40px;
  margin-bottom: 16px;
  border-top: 4px solid var(--color-primary);
`;

export const Title = styled.strong`
  font-size: 40px;
  font-weight: 900;

  @media (max-width: 470px) {
    max-width: 250px;

    font-size: 30px;
  }
`;

export const Message = styled.span`
  font-size: 16px;
  color: var(--color-control);

  @media (min-width: 1081px) {
    max-width: 400px;
  }

  @media (max-width: 470px) {
    font-size: 14px;
  }
`;

export const InputContainer = styled.div`
  width: 600px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  margin-top: 32px;

  > div {
    width: 100%;
  }

  > div + div {
    margin-top: 32px;
  }

  @media (max-width: 1300px) {
    width: 500px;
  }

  @media (max-width: 1080px) {
    width: 100%;
  }
`;

export const BottomLeft = styled.div`
  width: 102%;
  height: 100%;

  background: url(${bottomLeft}) no-repeat bottom left;

  position: absolute;

  z-index: -1;
  margin-left: -1px;
  margin-bottom: -1px;
`;

export const TopRight = styled.div`
  width: 100%;
  height: 100vh;

  background: url(${topRight}) no-repeat top right;

  position: absolute;

  z-index: -1;
  margin-top: -1px;
`;

export const ButtonRipple = styled(Ripple)`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 60px;
  min-height: 60px;

  margin-top: 32px;

  border: 0;
  border-radius: 32px 0 32px 32px;

  background: var(--color-primary);

  box-shadow: var(--box-shadow);
  transition: box-shadow 0.4s;
  cursor: pointer;

  &:hover {
    box-shadow: var(--box-shadow-hover);
  }

  span {
    color: var(--color-white);
  }

  @media (min-width: 471px) {
    display: none;
  }
`;

export const SuccessMessage = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 930px) {
    flex-direction: column;

    > * {
      text-align: center;
    }
  }
`;

export const Wrapper = styled.div`
  margin-left: 64px;

  display: flex;
  flex-direction: column;

  span {
    font-size: 20px;
    max-width: 600px;
  }

  strong {
    font-size: 40px;
    font-weight: 500;

    max-width: 600px;

    margin-bottom: 16px;
  }

  @media (max-width: 930px) {
    margin-left: 0;
    margin-top: 32px;

    strong {
      font-size: 24px;
    }

    span {
      font-size: 20px;
    }
  }
`;
