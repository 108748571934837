import styled, { css } from 'styled-components';

import Tooltip from '../Tooltip';

interface IInputProps {
  hasError: boolean;
  hasFocus: boolean;
  hasContent: boolean;
}

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const Label = styled.label`
  display: flex;
  justify-content: space-between;

  font-size: 12px;
  color: var(--color-control);

  > a {
    font-size: 14px;
    text-decoration: none;
    transition: opacity 0.3s;
    color: var(--color-primary);
  }

  > a:hover {
    opacity: 0.6;
  }
`;

export const StyledInput = styled.input<IInputProps>`
  border: 0;

  ${({ hasError, hasContent, hasFocus }) =>
    hasError
      ? css`
          border-bottom: 1px solid var(--color-error);
        `
      : css`
          border-bottom: 1px solid
            ${hasContent || hasFocus
              ? 'var(--color-primary)'
              : 'var(--color-control)'};
        `}

  padding: 16px 36px 16px 0;
`;

export const Error = styled(Tooltip)`
  height: 20px;

  top: 36px;
  right: 48px;
  position: absolute;

  svg {
    margin: 0;
  }

  span {
    color: var(--color-white);
    background: var(--color-error);

    &::before {
      border-color: var(--color-error) transparent;
    }
  }
`;

export const Eye = styled.div`
  height: 20px;

  top: 36px;
  right: 12px;
  position: absolute;

  cursor: pointer;
`;

export const BottomError = styled.span`
  margin-top: 4px;

  font-size: 13px;
  color: var(--color-primary);
`;
