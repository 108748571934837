import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import * as Yup from 'yup';
import qs from 'query-string';
import { Base64 } from 'js-base64';
import Lottie from 'lottie-react-web';
import { FormHandles } from '@unform/core';
import { FiArrowLeft } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillHeart, AiFillFacebook } from 'react-icons/ai';

import { IState } from '../../store';
import { IAuthState } from '../../store/modules/auth/types';

import {
  resetError,
  resetSuccess,
  signInRequest,
  storeRedirect,
  storeSubdomain,
} from '../../store/modules/auth/actions';

import { getValidationErrors } from '../../utils/errors';
import { decryptCredentials } from '../../utils/password';
import animation from '../../assets/animations/loading-signin.json';

import Input from '../../components/Input';
import Snack from '../../components/Snack';
import RememberMe from '../../components/RememberMe';

import { colorPrimary, colorWhiteTransparency } from '../../styles/global';

import {
  Header,
  Button,
  Footer,
  Content,
  Container,
  BackButton,
  Background,
  SignInForm,
  ButtonRipple,
  FacebookButton,
  InputContainer,
  NamedSeparator,
  CreateAccountButton,
  FacebookButtonRipple,
} from './styles';
import { objectToQueryParams } from '../../utils/uri';

interface ISignInFormDataProps {
  e7w2ycmv: string;
  oi6auv7q: string;
}

const SignInPage: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const dispatch = useDispatch();

  const history = useHistory();

  const {
    error,
    token,
    loading,
    redirect,
    subdomain,
    facebookLoading,
  } = useSelector<IState, IAuthState>(state => state.auth);

  const [localError, setLocalError] = useState('');
  const [inputType, setInputType] = useState<'password' | 'text'>('password');

  const [isRememberMeChecked, setIsRememberMeChecked] = useState(() => {
    const rememberMe = localStorage.getItem('@BSFOOD:rememberme');

    if (rememberMe) {
      if (rememberMe === 'false') {
        return false;
      }

      return true;
    }

    return false;
  });

  useEffect(() => {
    if (history.location.search) {
      const { redirect: searchRedirect, subdomain: searchSubdomain } = qs.parse(
        history.location.search,
      );

      if (
        searchRedirect &&
        searchSubdomain &&
        typeof searchRedirect === 'string' &&
        typeof searchSubdomain === 'string'
      ) {
        dispatch(storeRedirect(searchRedirect));
        dispatch(storeSubdomain(searchSubdomain));
      } else {
        history.push('/error');
      }
    } else if (!subdomain || !redirect) {
      history.push('/error');
    }
  }, [history, redirect, subdomain, dispatch]);

  useEffect(() => {
    const credentials = localStorage.getItem('@BSFOOD:credentials');

    if (credentials) {
      const decrypted = decryptCredentials(credentials);

      formRef.current?.setFieldValue('e7w2ycmv', decrypted.e7w2ycmv);
      formRef.current?.setFieldValue('oi6auv7q', decrypted.oi6auv7q);
    }
  }, []);

  useEffect(() => {
    if (history.location.pathname === '/session-expired') {
      setLocalError('Entre novamente e continue usando.');
    }
  }, [history]);

  useEffect(() => {
    if (redirect && subdomain && token) {
      const params = qs.parse(history.location.search);
      delete params.subdomain;
      delete params.redirect;

      const queryParams = objectToQueryParams(params);

      switch (process.env.REACT_APP_ENV) {
        case 'production': {
          window.location.replace(
            `https://${subdomain}.bsfood.com.br/loading/?token=${token}&redirect=${redirect}&${queryParams}`,
          );
          break;
        }

        case 'staging': {
          window.location.replace(
            `https://menustaging.bsfood.com.br/loading/?token=${token}&redirect=${redirect}&${queryParams}`,
          );
          break;
        }

        default: {
          window.location.replace(
            `http://localhost:3000/loading/?token=${token}&redirect=${redirect}&${queryParams}`,
          );
        }
      }
    }
  }, [redirect, subdomain, token, history]);

  useEffect(() => {
    if (error) {
      if (error.includes('incorreta')) {
        formRef.current?.setFieldError('e7w2ycmv', error);
        formRef.current?.setFieldError('oi6auv7q', error);
      } else {
        setLocalError(error);
      }

      dispatch(resetError());
    }
  }, [error, dispatch]);

  useEffect(() => {
    dispatch(resetSuccess());
  }, [dispatch]);

  const handleSignIn = useCallback(
    async ({ e7w2ycmv, oi6auv7q }: ISignInFormDataProps) => {
      if (loading) {
        return;
      }

      const captcha = await recaptchaRef.current?.executeAsync();
      recaptchaRef.current?.reset();

      if (!captcha || captcha === 'null') {
        setLocalError('CAPTCHA inválido!');
        return;
      }

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('recaptcha: ', captcha);
      }

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          e7w2ycmv: Yup.string()
            .email('Digite um email válido.')
            .required('O email é obrigatório.'),
          oi6auv7q: Yup.string().required('A senha é obrigatória.'),
        });

        await schema.validate({ e7w2ycmv, oi6auv7q }, { abortEarly: false });

        dispatch(
          signInRequest({
            email: e7w2ycmv,
            password: oi6auv7q,
            token: captcha,
          }),
        );

        if (isRememberMeChecked) {
          const encrypted = Base64.encode(
            `{"e7w2ycmv":"${e7w2ycmv}","oi6auv7q":"${oi6auv7q}"}`,
          );

          localStorage.setItem('@BSFOOD:credentials', encrypted);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        } else {
          setLocalError('Verifique sua conexão e tente novamente.');
        }
      }
    },
    [loading, isRememberMeChecked, dispatch],
  );

  const handleOnRememberMeClicked = useCallback(() => {
    setIsRememberMeChecked(oldState => {
      if (oldState) {
        localStorage.setItem('@BSFOOD:rememberme', 'false');
        localStorage.removeItem('@BSFOOD:credentials');
      } else {
        localStorage.setItem('@BSFOOD:rememberme', 'true');
      }

      return !oldState;
    });
  }, []);

  const handleOnTogglePassword = useCallback(() => {
    setInputType(prevState => {
      if (prevState === 'password') {
        return 'text';
      }

      return 'password';
    });
  }, []);

  const handleOnErrorDismissed = useCallback(() => {
    setLocalError('');
  }, []);

  const handleOnNewAccountClicked = useCallback(() => {
    history.push('/signup');
  }, [history]);

  const redirectURI = useMemo(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      return `https://auth.bsfood.com.br/facebook-login/`;
    }

    return `https://authstaging.bsfood.com.br/facebook-login/`;
  }, []);

  const getState = useCallback(async () => {
    const captcha = await recaptchaRef.current?.executeAsync();
    recaptchaRef.current?.reset();

    return Base64.btoa(
      JSON.stringify({
        redirect,
        subdomain,
        token: captcha,
      }),
    );
  }, [redirect, subdomain]);

  const handleFacebookSignIn = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      const state = await getState();

      window.location.replace(
        `https://facebook.com/v10.0/dialog/oauth?client_id=645447533023947&scope=email&response_type=token&redirect_uri=${redirectURI}&state=${state}`,
      );
    },
    [redirectURI, getState],
  );

  const handleOnBackPressed = useCallback(() => {
    window.location.replace(`https://${subdomain}.bsfood.com.br`);
  }, [subdomain]);

  return (
    <Container>
      <Content className="has-custom-scroll-bar">
        <Header>
          <BackButton onClick={handleOnBackPressed}>
            <FiArrowLeft size={24} />
            <span>Voltar ao menu</span>
          </BackButton>
        </Header>
        <SignInForm ref={formRef} onSubmit={handleSignIn}>
          <h2>Login.</h2>
          {facebookLoading ? (
            <FacebookButtonRipple color={colorWhiteTransparency}>
              <Lottie
                options={{
                  animationData: animation,
                }}
                width={60}
                height={60}
              />
            </FacebookButtonRipple>
          ) : (
            <FacebookButton onClick={handleFacebookSignIn} type="button">
              <AiFillFacebook size={24} />
              <span>Entrar com Facebook</span>
            </FacebookButton>
          )}
          <CreateAccountButton
            onClick={handleOnNewAccountClicked}
            type="button"
          >
            Criar uma conta
          </CreateAccountButton>
          <NamedSeparator>
            <hr />
            <span>ou</span>
            <hr />
          </NamedSeparator>
          <InputContainer>
            <Input
              type="email"
              title="Email"
              name="e7w2ycmv"
              errorLocation="BOTTOM"
              placeholder="você@exemplo.com.br"
            />
            <RememberMe
              checked={isRememberMeChecked}
              onChange={handleOnRememberMeClicked}
            />
            <Input
              title="Senha"
              hasForgotLink
              hasPasswordEye
              name="oi6auv7q"
              type={inputType}
              errorLocation="BOTTOM"
              placeholder="sua senha"
              handleTogglePassword={handleOnTogglePassword}
            />
          </InputContainer>
          <Button type="submit">
            <ButtonRipple color={colorWhiteTransparency}>
              {loading ? (
                <Lottie
                  options={{
                    animationData: animation,
                  }}
                  height={60}
                  width={60}
                />
              ) : (
                <span>Entrar com Email</span>
              )}
            </ButtonRipple>
          </Button>
          <Footer>
            <div className="privacy-policy">
              <Link to="/privacy-policy">Política de Privacidade</Link>
            </div>
            <span>
              Feito com
              <AiFillHeart color={colorPrimary} size={20} />
              por
              <a
                target="_blank"
                href="https://bsfood.com.br/"
                rel="noopener noreferrer"
              >
                Build Solutions
              </a>
              <span>.</span>
            </span>
          </Footer>
        </SignInForm>
      </Content>
      <Background />
      <ReCAPTCHA
        size="invisible"
        ref={recaptchaRef}
        sitekey={`${process.env.REACT_APP_CAPTCHA_KEY}`}
      />
      {localError && (
        <Snack message={localError} onDismiss={handleOnErrorDismissed} />
      )}
    </Container>
  );
};

export default SignInPage;
