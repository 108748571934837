import {
  ActionTypes,
  ResetErrorAction,
  ResetSuccessAction,
  StoreRedirectAction,
  SignInRequestAction,
  SignInSuccessAction,
  SignInFailureAction,
  SignUpRequestAction,
  StoreSubdomainAction,
  ResetPasswordRequestAction,
  ResetPasswordSuccessAction,
  ResetPasswordFailureAction,
  ForgotPasswordRequestAction,
  ForgotPasswordSuccessAction,
  ForgotPasswordFailureAction,
  FacebookSignInRequestAction,
} from './types';

import ISignInRequest from '../../../dtos/ISignInRequest';
import ISignUpRequest from '../../../dtos/ISignUpRequest';
import IResetPasswordRequest from '../../../dtos/IResetPasswordRequest';
import IForgotPasswordRequest from '../../../dtos/IForgotPasswordRequest';
import ISignInFacebookRequest from '../../../dtos/ISignInFacebookRequest';

export const resetError = (): ResetErrorAction => {
  return {
    type: ActionTypes.RESET_ERROR,
  };
};

export const resetSuccess = (): ResetSuccessAction => {
  return {
    type: ActionTypes.RESET_SUCCESS,
  };
};

export const storeRedirect = (redirect: string): StoreRedirectAction => {
  return {
    type: ActionTypes.STORE_REDIRECT,
    payload: redirect,
  };
};

export const storeSubdomain = (subdomain: string): StoreSubdomainAction => {
  return {
    type: ActionTypes.STORE_SUBDOMAIN,
    payload: subdomain,
  };
};

export const signInRequest = (request: ISignInRequest): SignInRequestAction => {
  return {
    type: ActionTypes.SIGN_IN_REQUEST,
    payload: request,
  };
};

export const signInSuccess = (token: string): SignInSuccessAction => {
  return {
    type: ActionTypes.SIGN_IN_SUCCESS,
    payload: token,
  };
};

export const signInFailure = (error: string): SignInFailureAction => {
  return {
    type: ActionTypes.SIGN_IN_FAILURE,
    payload: error,
  };
};

export const signUpRequest = (request: ISignUpRequest): SignUpRequestAction => {
  return {
    type: ActionTypes.SIGN_UP_REQUEST,
    payload: request,
  };
};

export const resetPasswordRequest = (
  request: IResetPasswordRequest,
): ResetPasswordRequestAction => {
  return {
    type: ActionTypes.RESET_PASSWORD_REQUEST,
    payload: request,
  };
};

export const resetPasswordSuccess = (): ResetPasswordSuccessAction => {
  return {
    type: ActionTypes.RESET_PASSWORD_SUCCESS,
  };
};

export const resetPasswordFailure = (
  error: string,
): ResetPasswordFailureAction => {
  return {
    type: ActionTypes.RESET_PASSWORD_FAILURE,
    payload: error,
  };
};

export const forgotPasswordRequest = (
  request: IForgotPasswordRequest,
): ForgotPasswordRequestAction => {
  return {
    type: ActionTypes.FORGOT_PASSWORD_REQUEST,
    payload: request,
  };
};

export const forgotPasswordSuccess = (): ForgotPasswordSuccessAction => {
  return {
    type: ActionTypes.FORGOT_PASSWORD_SUCCESS,
  };
};

export const forgotPasswordFailure = (
  error: string,
): ForgotPasswordFailureAction => {
  return {
    type: ActionTypes.FORGOT_PASSWORD_FAILURE,
    payload: error,
  };
};

export const facebookSignIn = (
  request: ISignInFacebookRequest,
): FacebookSignInRequestAction => {
  return {
    type: ActionTypes.FACEBOOK_SIGN_IN_REQUEST,
    payload: request,
  };
};
