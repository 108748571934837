import React, {
  useRef,
  useState,
  useCallback,
  InputHTMLAttributes,
} from 'react';

import { FiAlertCircle, FiCopy } from 'react-icons/fi';

import {
  Error,
  Label,
  Container,
  StyledInput,
  Copy,
  BottomError,
} from './styles';

interface IFormlessInputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  variant?: 'COPIABLE' | 'STANDARD';
  errorLocation?: 'BOTTOM' | 'RIGHT';
  onCopy?: () => void;
}

const FormlessInput: React.FC<IFormlessInputProps> = ({
  error,
  name,
  value,
  title,
  variant,
  errorLocation = 'RIGHT',
  onCopy,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [hasFocus, setHasFocus] = useState(false);
  const [hasContent, setHasContent] = useState(false);

  const handleOnFocus = useCallback(() => {
    setHasFocus(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setHasFocus(false);
    setHasContent(!!inputRef.current?.value);
  }, []);

  return (
    <Container>
      <Label htmlFor={name}>{title}</Label>
      <StyledInput
        value={value}
        ref={inputRef}
        hasError={!!error}
        hasFocus={hasFocus}
        hasContent={hasContent}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        {...rest}
      />
      {variant === 'COPIABLE' && !error && (
        <Copy onClick={onCopy}>
          <FiCopy size={20} />
        </Copy>
      )}
      {error && errorLocation === 'RIGHT' && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
      {error && errorLocation === 'BOTTOM' && (
        <BottomError>{error}</BottomError>
      )}
    </Container>
  );
};

export default FormlessInput;
