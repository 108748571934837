import ISignInRequest from '../../dtos/ISignInRequest';
import ISignInFacebookRequest from '../../dtos/ISignInFacebookRequest';
import ISignUpRequest from '../../dtos/ISignUpRequest';
import IResetPasswordRequest from '../../dtos/IResetPasswordRequest';
import IForgotPasswordRequest from '../../dtos/IForgotPasswordRequest';

import api from './api';

const signIn = async (request: ISignInRequest): Promise<string> => {
  const response = await api.post('accounts/login', request);
  return response.data.accessToken;
};

const signUp = async (request: ISignUpRequest): Promise<string> => {
  const response = await api.post('accounts/register-user', request);
  return response.data.accessToken;
};

const resetPassword = async (request: IResetPasswordRequest): Promise<void> => {
  await api.post('accounts/reset-password', request);
};

const forgotPassword = async (
  request: IForgotPasswordRequest,
): Promise<void> => {
  await api.post('accounts/forgot-password', request);
};

const facebookSignIn = async (
  request: ISignInFacebookRequest,
): Promise<string> => {
  const response = await api.post('accounts/login-facebook', request);
  return response.data.accessToken;
};

export default {
  signIn,
  signUp,
  resetPassword,
  forgotPassword,
  facebookSignIn,
};
