import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --color-primary: #db1622;
    --color-primary-light: #f6adb2;

    --color-white: #fff;
    --color-white-transparency: #fff6;

    --color-control: #aaa;
    --color-control-light: #f4f4f4;

    --color-error: #c53030;
    --color-success: #aaf6e1;
    --color-success-transparency: #aaf6e160;

    --color-black: #1b1c1e;
    --color-facebook: #4c69ba;

    --color-background: #f9f7f7;
    --color-background-dark: #e3dada;

    --box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.1);
    --box-shadow-hover: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);

    --box-shadow-large: 6px 6px 24px 0 rgba(0, 0, 0, 0.6);
  }
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Poppins', serif;

  }
  body {
    -webkit-font-smoothing: antialiased;
  }
  body, input, button, textarea {
    font-size: 16px;
  }
  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }
  button {
    cursor: pointer;
  }

  .button-facebook {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    min-height: 60px;
    margin-top: 16px;
    border: 0;
    border-radius: 32px 0 32px 32px;
    background: #4c69ba;
    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.4s;
    cursor: pointer;

    font-size: 16px;

    color: #fff;

    &:hover {
      box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);
    }

    svg {
      margin-right: 16px;
    }
  }

  .has-custom-scroll-bar {
    @media(min-width: 1000px) {
      ::-webkit-scrollbar {
        width: 6px;
      }

      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      ::-webkit-scrollbar-thumb {
        background: #636e72;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #2d3436;
      }
    }
  }

  .has-custom-scroll-bar2 {
    @media(min-width: 1000px) {
      &:hover {
        &::-webkit-scrollbar-thumb {
          border: 12px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
          background-color: rgba(0, 0, 0, 0.1);
          box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
            inset 1px 1px 0px rgba(0, 0, 0, 0.05);
          border-radius: 24px;
        }
      }
      &::-webkit-scrollbar {
        width: 28px;
      }
      &::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: transparent;
      }
      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
      }
      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
    }
  }

  ::-moz-selection {
    background: var(--color-primary);
    color: var(--color-white);
  }

  ::selection {
    background: var(--color-primary);
    color: var(--color-white);
  }
`;

export const colorWhite = '#fff';
export const colorPrimary = '#db1622';
export const colorWhiteTransparency = '#fff6';
