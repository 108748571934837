import { Reducer } from 'redux';

import { ActionTypes, AuthActions, IAuthState } from './types';

const INITIAL_STATE: IAuthState = {
  error: '',
  token: '',
  redirect: '',
  subdomain: '',
  loading: false,
  success: 'NONE',
  facebookLoading: false,
};

const auth: Reducer<IAuthState, AuthActions> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ActionTypes.RESET_ERROR: {
      return { ...state, error: '' };
    }

    case ActionTypes.RESET_SUCCESS: {
      return { ...state, success: 'NONE' };
    }

    case ActionTypes.STORE_REDIRECT: {
      return { ...state, redirect: action.payload };
    }

    case ActionTypes.STORE_SUBDOMAIN: {
      return { ...state, subdomain: action.payload };
    }

    case ActionTypes.SIGN_IN_REQUEST: {
      return { ...state, loading: true };
    }

    case ActionTypes.SIGN_IN_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.payload,
        facebookLoading: false,
      };
    }

    case ActionTypes.SIGN_IN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        facebookLoading: false,
      };
    }

    case ActionTypes.SIGN_UP_REQUEST: {
      return { ...state, loading: true };
    }

    case ActionTypes.RESET_PASSWORD_REQUEST: {
      return { ...state, loading: true, success: 'NONE' };
    }

    case ActionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: 'RESET',
      };
    }

    case ActionTypes.RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case ActionTypes.FORGOT_PASSWORD_REQUEST: {
      return { ...state, loading: true, success: 'NONE' };
    }

    case ActionTypes.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: 'FORGOT',
      };
    }

    case ActionTypes.FORGOT_PASSWORD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case ActionTypes.FACEBOOK_SIGN_IN_REQUEST: {
      return { ...state, facebookLoading: true };
    }

    default: {
      return state;
    }
  }
};

export default auth;
