import styled, { keyframes } from 'styled-components';

import { Form } from '@unform/web';
import Ripple from 'react-ripples';

import background from '../../assets/login-background.jpg';

const appearFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to{
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  height: 100vh;

  display: flex;

  overflow: hidden;
  transition: flex-direction 0.4s;
`;

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 650px;

  display: flex;
  flex-direction: column;

  overflow-y: auto;
  background: var(--color-white);
  animation: ${appearFromLeft} 0.8s;

  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100vh;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${background});

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Header = styled.header`
  display: flex;
  padding: 32px 32px 16px 32px;
  background: var(--color-white);
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  span {
    font-size: 14px;

    @media (min-width: 800px) {
      font-size: 16px;
    }
  }

  border: 0;
  outline: 0;
  background: none;
`;

export const SignInForm = styled(Form)`
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 0px 86px 32px 86px;

  background: var(--color-white);

  > h2 {
    font-size: 40px;
    margin-bottom: 16px;
  }

  @media (max-width: 800px) {
    padding: 32px;
  }
`;

export const SignUpForm = styled.form`
  height: 100%;

  display: flex;
  flex-direction: column;

  padding: 32px 86px;

  background: var(--color-white);

  > h2 {
    font-size: 40px;
    margin-bottom: 32px;
  }

  @media (max-width: 800px) {
    padding: 32px;
  }
`;

export const InputContainer = styled.div`
  > div {
    margin-bottom: 8px;
  }

  > div + div {
    margin-bottom: 32px;
  }
`;

export const ButtonRipple = styled(Ripple)`
  width: 100%;
  height: 60px;
  min-height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  border-radius: 32px 0 32px 32px;

  color: var(--color-white);
  box-shadow: var(--box-shadow);
  background: var(--color-primary);

  > div {
    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }
  }
`;

export const FacebookButtonRipple = styled(Ripple)`
  width: 100%;
  height: 60px;
  min-height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  margin-top: 16px;
  border-radius: 32px 0 32px 32px;
  background: var(--color-facebook);
`;

export const Button = styled.button`
  height: 60px;
  min-height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  margin-top: 16px;
  border-radius: 32px 0 32px 32px;

  transition: box-shadow 0.4s;

  color: var(--color-white);
  box-shadow: var(--box-shadow);
  background: var(--color-primary);

  &:hover {
    box-shadow: var(--box-shadow);
  }

  cursor: pointer;
`;

export const FacebookButton = styled.button`
  height: 60px;
  min-height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  margin-top: 16px;
  border-radius: 32px 0 32px 32px;

  transition: box-shadow 0.4s;

  color: var(--color-white);
  box-shadow: var(--box-shadow);
  background: var(--color-facebook);

  &:hover {
    box-shadow: var(--box-shadow);
  }

  span {
    margin-left: 16px;
  }

  cursor: pointer;
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  margin-top: auto;

  background: var(--color-white);

  .privacy-policy {
    a {
      text-decoration: underline;
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    text-decoration: none;
    color: var(--color-primary);

    font-size: 16px;
    margin-left: 4px;
    text-align: center;
    transition: opacity 0.3s;
  }

  a:hover {
    opacity: 0.6;
  }

  svg {
    margin: 0 4px;
  }

  @media (max-width: 850px) {
    font-size: 14px;

    a {
      font-size: 14px;
    }
  }

  @media (max-height: 800px) {
    margin-top: 32px;
  }

  @media (max-height: 680px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

export const NamedSeparator = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;

  margin: 16px 0;

  hr {
    height: 1px;
    width: 100%;
  }

  span {
    margin-left: 8px;
    margin-right: 8px;
  }
`;

export const CreateAccountButton = styled.button`
  height: 60px;
  min-height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  margin-top: 16px;
  border-radius: 32px 0 32px 32px;

  transition: box-shadow 0.4s;

  color: var(--color-white);
  box-shadow: var(--box-shadow);
  background: var(--color-primary);

  &:hover {
    box-shadow: var(--box-shadow);
  }

  cursor: pointer;
`;
