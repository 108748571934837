import styled from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled(animated.div)`
  z-index: 999;
  position: absolute;

  @media (min-width: 801px) {
    left: 50%;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 0 16px;
  }
`;

export const Content = styled.div`
  padding: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--color-primary);
  background: var(--color-white);

  border-radius: 8px;
  border-bottom: 2px solid var(--color-primary);

  box-shadow: var(--box-shadow-large);

  bottom: 16px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-left: 8px;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Message = styled.span`
  margin-left: 14px;
  margin-right: 14px;
`;

export const DismissButton = styled.button`
  display: flex;
  align-items: center;

  height: 100%;

  border: 0;
  background: none;

  margin-right: 8px;
`;
