import React from 'react';

import { Switch, Redirect, Route } from 'react-router-dom';

import ErrorPage from '../pages/ErrorPage';
import SignInPage from '../pages/SignInPage';
import SignUpPage from '../pages/SignUpPage';
import FacebookLoginPage from '../pages/FacebookLoginPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" component={SignInPage} exact />

      <Route path="/error" component={ErrorPage} />
      <Route path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route path="/signup" component={SignUpPage} />
      <Route path="/session-expired" component={SignInPage} />
      <Route path="/facebook-login" component={FacebookLoginPage} />
      <Route path="/forgot-password" component={ForgotPasswordPage} />

      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
