import React, { useEffect } from 'react';

import qs from 'query-string';
import { Base64 } from 'js-base64';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  storeRedirect,
  facebookSignIn,
  storeSubdomain,
} from '../../store/modules/auth/actions';

import { IAuthState } from '../../store/modules/auth/types';

import { IState } from '../../store';

import { Container } from './styles';

const FacebookLoginPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { token, redirect, subdomain } = useSelector<IState, IAuthState>(
    state => state.auth,
  );

  useEffect(() => {
    if (redirect && subdomain && token) {
      switch (process.env.REACT_APP_ENV) {
        case 'production': {
          window.location.replace(
            `https://${subdomain}.bsfood.com.br/loading/?token=${token}&redirect=${redirect}`,
          );
          break;
        }

        case 'staging': {
          window.location.replace(
            `https://menustaging.bsfood.com.br/loading/?token=${token}&redirect=${redirect}`,
          );
          break;
        }

        default: {
          window.location.replace(
            `http://localhost:3000/loading/?token=${token}&redirect=${redirect}`,
          );
        }
      }
    }
  }, [redirect, subdomain, token]);

  useEffect(() => {
    const { access_token, state } = qs.parse(history.location.hash);

    if (typeof state === 'string' && typeof access_token === 'string') {
      const stateObject = JSON.parse(Base64.atob(state));

      dispatch(storeRedirect(stateObject.redirect));
      dispatch(storeSubdomain(stateObject.subdomain));

      dispatch(
        facebookSignIn({
          accessToken: access_token,
          token: stateObject.token,
        }),
      );
    } else {
      history.push('/error');
    }
  }, [history, dispatch]);

  return <Container />;
};

export default FacebookLoginPage;
